@import '../../../scss/shared-variables';
@import 'bootstrap-variables';
@import 'core-variables';
@import '../../../scss/shared';

.text-muted {
  color: $gray-400 !important;
}

.bg-custom {
  background-color: #1b2a4e !important;
}

.sidebar-nav .nav-item.open > .nav-link,
.sidebar-nav .nav-link:hover {
  background-color: #203057;
}

.sidebar-nav .nav-item .nav .nav-link.active {
  background-color: rgba(255, 255, 255, 0.02) !important;
}

.sidebar-nav .nav-item.open .nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-item.active > .nav-link {
  background-color: #243661;
}
